.tile {
	position: absolute;
	left: 0;
	top: 0;
	width: 25%;
	height: 25%;
	transition: left 0.3s ease-in-out, top 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	font-family: 'Gilroy';
}

.tile {
	border: 1px solid #dee2e6;
	border-radius: 4px;
	background-color: rgb(106, 56, 173, 0.8);
	cursor: pointer;
	z-index: 2;
}

.tile.tile--empty {
	background-color: transparent !important;
	border: 1px solid transparent;
	z-index: 1;
}

.tile.tile--done {
	color: orange;
}

.tile__inner {
	font-size: 3.2rem;
	color: white;
	font-weight: 500;
}
