.tiles-wrapper {
	position: relative;
	border: 1px solid var(--dark);
	padding: 2px;
	/* max-width: 500px; */
	width: 68.5%;
	margin: auto;
}

.tiles {
	position: relative;
	width: 100%;
	height: 100%;
}

.tiles:before {
	content: '';
	display: block;
	padding-top: 100%;
}
