@media only screen and (max-width: 479px)
	html
		font-size: 5px
@media only screen and (min-width: 480px) and (max-width: 559px)
	html
		font-size: 6px
@media only screen and (min-width:560px) and (max-width: 679px)
	html
		font-size: 7px
@media only screen and (min-width:680px) and (max-width: 799px)
	html
		font-size: 8px
@media only screen and (min-width:800px) and (max-width: 959px)
	html
		font-size: 9px
@media only screen and (min-width:960px) and (max-width: 1100px)
	html
		font-size: 10px
@media only screen and (min-width: 1101px) and (max-width: 1200px)
	html
		font-size: 11px
@media only screen and (min-width: 1201px) and (max-width: 1450px)
	html
		font-size: 12px
		.Hanoi
			.hanoi-title
				font-size: 1.2rem !important
		.arcade
			.puzzle-title
				font-size: 1.2rem !important
				.container1
		// .quiz
		// 	.question
		// 		max-width: 70% !important
@media only screen and (min-width: 1451px) and (max-width: 1599px)
	html
		font-size: 13px
@media only screen and (min-width: 1600px)
	html
		font-size: 16px
		.container1
			.quiz
				.question
					max-width: 70% !important

.App
	button
		background: none
		border: none
		padding: 0
		margin: 0
	.Main
		.start
			button.exitBtn
				&:hover
					transform: scale(1.2)
			button.fwdBtn
				position: relative
				cursor: pointer
				display: flex
				align-items: center
				justify-content: center
				width: 25%
				height: 9%
				border: none
				background-color: transparent
				color: white
				font-size: 2rem
				font-family: 'Gilroy-Bold'
				margin: 0
				padding: 0
				& > svg
					width: 100%
					height: auto
				& > *
					position: absolute
				p
					margin: 5% 0 0 0
				&:hover
					transform: scale(1.1)
		.container1
			.map
				.arrow
					position: absolute
					svg
						width: 100%
						height: auto
					&-down
						width: 7.1%
						top: 62%
						left: 45%
						transform: rotate(90deg)
					&-up
						width: 7.1%
						top: 29%
						left: 45%
						transform: rotate(270deg)
					&-left
						width: 7.53%
						top: 45%
						left: 30%
						transform: rotate(180deg)
					&-right
						width: 7.53%
						top: 45%
						left: 61%
						transform: rotate(-3deg)
				.card
					position: absolute
					display: flex
					justify-content: center
					align-content: center
					align-items: center
					perspective: 1000px
					&:hover
						.front
							transform: rotateY(180deg)
						.back
							transform: rotateY(0deg)
					.front, .back, .result
						position: absolute
						font-family: 'Gilroy'
						cursor: pointer
						color: white
						display: flex
						flex-direction: column
						align-items: center
						justify-content: center
						align-content: center
						width: 100%
						overflow: hidden
					.front
						-webkit-backface-visibility: hidden
						backface-visibility: hidden
						transform-style: preserve-3d
						transition: ease-in-out 600ms
						img
							width: 100%
						// & > h3
						// 	-webkit-backface-visibility: hidden
						// 	backface-visibility: hidden
						// 	cursor: pointer
						// 	position: absolute
						// 	font-family: 'Gilroy'
						// 	font-size: 2.3rem
						// 	color: white
					.back
						height: 100%
						-webkit-backface-visibility: hidden
						backface-visibility: hidden
						transform-style: preserve-3d
						transition: ease-in-out 600ms
						transform: rotateY(-180deg)
						cursor: pointer
						img
							position: absolute
							width: 100%
						// & > h5
						// 	z-index: 1
						// 	text-align: center
						// 	font-family: 'Gilroy-Semibold'
						// 	font-size: 1.2rem
						// 	margin-bottom: 1.3rem
						// 	margin-top: 0
						// 	width: 90%
						// & > button
						// 	cursor: pointer
						// 	z-index: 1
						// 	width: 100%
						// 	height: 20%
						// 	// min-height: 36px
						// 	border: none
						// 	margin: 0
						// 	background: url('./../assets/btn-begin.png') no-repeat
						// 	background-size: contain
						// 	background-position-x: center
					.result
						height: 100%
						img
							position: absolute
							width: 100%
						// h3
						// 	margin-top: 0
						// 	margin-bottom: 5px
						// 	font-size: 2rem
						// 	z-index: 1
						// svg
						// 	width: 50%
						// 	height: auto
						// 	z-index: 1
				// .crystal
				// 	position: absolute
				// 	cursor: pointer
			.quiz, .arcade
				.description
					p
						font-size: 1.7rem
						text-align: center
					button
						position: relative
						cursor: pointer
						display: flex
						align-items: center
						justify-content: center
						width: 30%
						height: 9%
						border: none
						background-color: transparent
						color: white
						font-size: 2rem
						font-family: 'Gilroy-Bold'
						margin: 12% auto
						padding: 0
						& > svg
							width: 100%
							height: auto
						& > *
							position: absolute
						p
							margin: 5% 0 0 0
						&:hover
							transform: scale(1.1)
				.progressBarContainer
					position: absolute
					top: 5%
					right: 5%
					width: 10.4%
					.timer
						font-family: 'Gilroy-Bold'
				.content
					position: absolute
					width: 55%
					right: 21%
					top: 11%
					display: flex
					align-items: center
					align-content: space-between
					justify-content: space-between
					flex-wrap: wrap
					.card
						width: calc(1/3*100% - 16px)
						margin: 8px
						height: 0
						padding-bottom: calc(1/3*109% - 16px)
						transition: 0.3s
						transform-style: preserve-3d
						position: relative
						cursor: pointer
						&.is-flipped
							transform: rotateY(180deg)
						&.is-inactive
							pointer-events: none
							.card-face
								&.card-back-face
									transform: unset
					img
						width: 100%
					.card-face
						backface-visibility: hidden
						position: absolute
						width: 100%
						height: 100%
						&.card-back-face
							transform: rotateY(180deg)
							width: 100%
				.topicName
					// display: block
					// font-family: 'Gilroy'
					// color: rgb(172, 132, 213)
					// font-size: 2.8rem
					// line-height: 1
					// margin-top: 11.13%
					// margin-bottom: 25px
				.question
					display: inline-block
					max-width: 80%
					font-family: 'Gilroy-Bold'
					color: white
					line-height: 1
					font-size: 4rem
					margin-top: 0
					margin-bottom: 0
				.variants
					position: relative
					display: flex
					flex-direction: column
					align-items: center
					margin-top: 3rem
					height: 0
					padding-bottom: 51.43%
					flex-wrap: nowrap
					max-width: 45%
					& > div
						position: relative
						display: flex
						align-items: center
						justify-content: center
						margin-bottom: 5px
						& > svg
							width: 100%
							height: auto
						&:hover
							transform: scale(1.1)
					button
						position: absolute
						background: transparent
						font-family: 'Gilroy'
						font-size: 1.6rem
						width: 85%
						line-height: 1
						cursor: pointer
						border: none
						color: white
				.exitContent
					.btnGroup
						button
							position: relative
							display: flex
							align-items: center
							justify-content: center
							cursor: pointer
							color: white
							font-size: 1.7rem
							width: 25%
							font-family: 'Gilroy-Bold'
							padding: 0
							height: 4rem
							&:first-child
								margin-right: 10px
							svg
								width: 100%
								height: auto
								position: absolute
		// .container2
			// .crystals
			// 	display: flex
			// 	width: 100%
			// 	height: 100%
			// 	align-items: center
			// 	align-content: center
			// 	flex-wrap: wrap
			// 	& > *
			// 		width: 25%
			// 		margin-bottom: 18px

@media only screen and (min-aspect-ratio: 2/1)
	.App
		.Main
			.start
				background-size: 82% !important
			.container1
				width: calc(50% - 50px) !important
				padding-bottom: calc(50% - 56px) !important

@media only screen and (max-width: 414px) and (orientation: portrait)
		.App
			.Main
				background: url('./../assets/rotate_please.svg') no-repeat 50% 50%
				height: 100% !important
				.start
					display: none !important
				.container1
					display: none !important
				.container2
					display: none !important

@media only screen and (max-height: 414px) and (orientation: landscape)
		.App
			.Main
				.start
					.content
						h4
							font-size: 1.2rem !important
						.text
							padding: 2% 4% !important
						h1
							font-size: 2rem !important
							line-height: 1 !important
						.innerContent
							p
								font-size: 1.4rem !important
							img
								width: 80% !important
						.fwdBtn
							font-size: 1.5rem !important
							height: 4% !important
							margin-bottom: 32px !important
						.exitBtn
							top: 5% !important
				.container1
					.quiz
						.topicName
							font-size: 2.3rem !important
							margin-top: 8% !important
							margin-bottom: 5% !important
						.question
							font-size: 1.4rem !important
						.variants
							button
								font-size: 1.12rem !important
					.arcade
						.puzzle-title
							font-size: 1rem !important
						.exitContent
							p
								font-size: 1rem !important
		.Hanoi
			.hanoi-title
				font-size: 1rem !important
