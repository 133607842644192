.Hanoi {
	pointer-events: none;
	.hanoi-title {
		position: absolute;
		font-family: 'Gilroy';
		font-size: 1.67rem;
		top: -29%;
		left: 23%;
		color: white;
		width: 52.46%;
	}
	.contend {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		text-align: center;
		width: 100%;
		height: 100%;
	}
	/* Controls/Logic */
	label,
	input {
		position: absolute;
		// top: -10vmin;
		margin: 0;
		border: 0;
		padding: 0;
		// width: 30vmin;
		width: calc(100%/3);
		// height: 52.5vmin;
		height: 100%;
		cursor: pointer;
		opacity: 0;
		pointer-events: none;
		-webkit-tap-highlight-color: transparent;
	}
	input:nth-child(6n + 2),
	input:checked + input + input + label {
		left: 0;
	}
	input:nth-child(6n + 3),
	input:checked + input + label {
		// left: 30vmin;
		left: calc(1/3 * 100%);
	}
	input:nth-child(6n + 4),
	input:checked + label {
		// left: 60vmin;
		left: calc(2/3 * 100%);
	}

	label,
	input:hover,
	input[readonly]:focus + input,
	input[readonly]:focus + input + input,
	input[readonly]:focus + input + input + input {
		pointer-events: initial;
	}
	input[readonly],
	input:nth-child(6n + 2):checked ~ input:nth-child(6n + 2),
	input:nth-child(6n + 3):checked ~ input:nth-child(6n + 3),
	input:nth-child(6n + 4):checked ~ input:nth-child(6n + 4) {
		pointer-events: none;
	}

	#one ~ input,
	#one ~ label {
		z-index: 6;
	}
	#two ~ input,
	#two ~ label {
		z-index: 5;
	}
	#three ~ input,
	#three ~ label {
		z-index: 4;
	}
	#four ~ input,
	#four ~ label {
		z-index: 3;
	}
	#five ~ input,
	#five ~ label {
		z-index: 2;
	}
	#six ~ input,
	#six ~ label {
		z-index: 1;
	}

	input#zero {
		z-index: 7;
	}
	input#zero:focus {
		pointer-events: none;
	}
	input[readonly]:focus + input:checked ~ #zero {
		left: 0;
		pointer-events: initial;
	}
	input[readonly]:focus + input + input:checked ~ #zero {
		// left: 30vmin;
		left: calc(1/3*100%);
		pointer-events: initial;
	}
	input[readonly]:focus + input + input + input:checked ~ #zero {
		// left: 60vmin;
		left: calc(2/3*100%);
		pointer-events: initial;
	}

	/* Discs */
	.discs {
		position: relative;
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-end;
		// margin: 10vmin auto 0;
		// width: 50vmin;
		width: 100%;
		// height: 38vmin;
		height: 100%;
	}

	.disc {
		z-index: 1;
		border-radius: 1vmin;
		height: 6vmin;
		pointer-events: none;
		transition: order 0.3s step-end;
	}
	input:checked + input + input + label + .disc {
		order: 3;
	}
	input:checked + input + label + .disc {
		order: 6;
	}
	input:checked + label + .disc {
		order: 9;
	}

	input:focus + input:checked + input + input + label + .disc {
		order: 1;
		transition: order 0s;
		animation: float 3s ease-in-out infinite alternate;
	}
	input:focus + input + input:checked + input + label + .disc {
		order: 4;
		transition: order 0s;
		animation: float 3s ease-in-out infinite alternate;
	}
	input:focus + input + input + input:checked + label + .disc {
		order: 7;
		transition: order 0s;
		animation: float 3s ease-in-out infinite alternate;
	}
	@keyframes float {
		50% {
			transform: translateY(-1vmin);
		}
	}

	input:focus + input:hover + input + input + label + .disc,
	input + input:active + input + input + label + .disc {
		order: 1;
	}
	input:focus + input + input:hover + input + label + .disc,
	input + input + input:active + input + label + .disc {
		order: 4;
	}
	input:focus + input + input + input:hover + label + .disc,
	input + input + input + input:active + label + .disc {
		order: 7;
	}

	.one {
		margin: 0 auto;
		// margin: 0 10vmin;
		width: calc(10/90*100%);
		// width: 10vmin;
		// background: linear-gradient(to right, #eea668, #ed7e1d, #e67e22);
		background: linear-gradient(180deg, #EAF59F 0%, #CFE89F 100%);
	}
	.two {
		margin: 0 auto;
		// margin: 0 8vmin;
		// width: 14vmin;
		width: calc(14/90*100%);
		background: linear-gradient(180deg, #BFDF9F 0%, #88CFAE 100%);
	}
	.three {
		// margin: 0 6vmin;
		margin: 0 auto;
		// width: 18vmin;
		width: calc(18/90*100%);
		background: linear-gradient(180deg, #7ACCB3 0%, #3DB9C5 100%);
	}
	.four {
		// margin: 0 4vmin;
		margin: 0 auto;
		// width: 22vmin;
		width: calc(22/90*100%);
		background: linear-gradient(180deg, #25AECD 0%, #3DB9C5 100%);
	}
	.five {
		// margin: 0 2vmin;
		margin: 0 auto;
		// width: 26vmin;
		width: calc(26/90*100%);
		background: linear-gradient(180deg, #23AAD0 0%, #2B8FE0 100%);
	}
	.six {
		// width: 30vmin;
		width: calc(1/3*100%);
		background: linear-gradient(180deg, #2C8FE0 0%, #3B77EB 100%);
	}

	/* Spacers/Separators */
	.spacer {
		// width: 30vmin;
		width: calc(1/3*100%);
		height: 1px;
		flex-grow: 0;
		pointer-events: none;
		transition: flex 0.3s;
	}
	.separator {
		width: 0;
		height: 100%;
	}
	.a {
		order: 2;
	}
	.ab {
		order: 3;
	}
	.b {
		order: 5;
	}
	.bc {
		order: 6;
	}
	.c {
		order: 8;
	}

	input[readonly]:focus + input:hover ~ .a,
	input[readonly]:focus + input + input:hover ~ .b,
	input[readonly]:focus + input + input + input:hover ~ .c {
		transition: flex 0s;
		flex-grow: 1;
	}
	input[readonly]:focus + input:checked ~ .a,
	input[readonly]:focus + input + input:checked ~ .b,
	input[readonly]:focus + input + input + input:checked ~ .c {
		flex-grow: 1;
	}

	/* Winning */
	.win {
		z-index: 7;
		position: absolute;
		left: 0;
		right: 0;
		top: -10vmin;
		bottom: -7.5vmin;
		color: #966f33;
		font-family: Arial, sans-serif;
		font-size: 6vmin;
		line-height: 17.5vmin;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s;
	}
	input:nth-child(6n + 4):checked
		~ input:nth-child(6n + 4):checked
		~ input:nth-child(6n + 4):checked
		~ input:nth-child(6n + 4):checked
		~ input:nth-child(6n + 4):checked
		~ input:nth-child(6n + 4):checked
		~ .win {
		opacity: 1;
		pointer-events: initial;
	}

	/* Everything Else */
	body {
		margin: 0;
		background-color: #f8f8f8;
	}

	h1 {
		margin: 0;
		padding: 5vmin;
		color: rgba(0, 0, 0, 0.5);
		font-family: Arial, sans-serif;
		font-size: 7.5vmin;
		font-weight: lighter;
		text-align: center;
		background: linear-gradient(to right, rgba(150, 111, 51, 0.4), rgba(150, 111, 51, 0.2)),
			repeating-linear-gradient(
				to right,
				#eec487,
				#eec487 3vmin,
				#f3cf9a 3vmin,
				#f3cf9a 6vmin,
				#f8d8a2 6vmin,
				#f8d8a2 9vmin,
				#f1ca88 9vmin,
				#f1ca88 12vmin,
				#f4d09e 12vmin,
				#f4d09e 15vmin,
				#faddb0 15vmin,
				#faddb0 18vmin,
				#eec88a 18vmin,
				#eec88a 21vmin
			);
	}

	form {
		text-align: center;
		height: 100%
	}

	.tower {
		position: absolute;
		// top: 7.5vmin;
		// border-radius: 1vmin;
		// width: 5vmin;
		// width: calc(5/90 * 100%);
		width: 13px;
		// height: 30vmin;
		height: 95%;
		// background: linear-gradient(to right, #d7b889, #b27315, #966f33);
		background: #13e6ff;
	}
	.a {
		// left: 12.5vmin;
		left: calc((15/90*100% - 6.5px));
	}
	.b {
		// left: 42.5vmin;
		left: calc(45/90*100% - 6.5px)
	}
	.c {
		// left: 72.5vmin;
		left: calc(75/90*100% - 6.5px);
	}

	.bottom {
		position: relative;
		display: block;
		margin: 0 auto;
		border-radius: 1vmin;
		width: 95vmin;
		height: 7.5vmin;
		background: linear-gradient(to right, rgba(255, 255, 255, 0.3), transparent),
			linear-gradient(
				#b27315,
				#966f33 13%,
				#faddb0 14.5%,
				#faddb0 27.5%,
				#966f33 29%,
				#966f33 42%,
				#faddb0 43.5%,
				#d7b889 56.5%,
				#966f33 58%,
				#966f33 71%,
				#d7b889 72.5%,
				#d7b889 85.5%,
				#966f33 87%,
				#b27315
			);
	}

	button {
		margin: 7.5vmin auto;
		border: none;
		border-radius: 1vmin;
		width: 35vmin;
		height: 10vmin;
		font-size: 4vmin;
		color: rgba(0, 0, 0, 0.5);
		background: linear-gradient(to right, #c39550, #966f33);
		outline: none;
		cursor: pointer;
		transition: background 0.3s;
	}
	button:hover {
		background: linear-gradient(to right, #caa163, #a77b39);
	}
	button:active {
		background: linear-gradient(to right, #cf9844, #9e6f29);
	}
}
